<template>
  <div id="exchangeDetails">
    <header-nav :content="`${$t('pages_router_016')} / ${ $t('pages_router_018')}`" />
    <div class="common-card-body">
      <div class="edit-btn">
        <a-button
          shape="round"
          type="primary"
          ghost
          :loading="exportLoading"
          @click="exportExchangeFlow"
        >
          <template #icon><download-outlined /></template>
          {{ exportLoading ? $t('common_text_023') : $t('pages_exchange_059') }}
        </a-button>
      </div>
      <div class="search-form ant-round-form">
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_exchange_047') }}: </span>
          <a-select
            v-model:value="searchQuery.sellCurrency"
            option-filter-prop="label"
            allow-clear
            show-search
            @change="loadExchangeList(1)"
          >
            <a-select-option
              v-for="item in sellCurList"
              :key="item.code"
              :value="item.code"
              :label="item.code"
            >
              <img :src="getCountryLogo(item.code)" class="select-img-country">
              <span>{{ item.name }}</span>
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_exchange_048') }}: </span>
          <a-select
            v-model:value="searchQuery.buyCurrency"
            allow-clear
            show-search
            option-filter-prop="label"
            @change="loadExchangeList(1)"
          >
            <a-select-option
              v-for="item in buyCurList"
              :key="item.code"
              :value="item.code"
              :label="item.code"
            >
              <img :src="getCountryLogo(item.code)" class="select-img-country">
              <span>{{ item.name }}</span>
            </a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_exchange_049') }}: </span>
          <a-select
            v-model:value="searchQuery.status"
            allow-clear
            show-search
            @change="loadExchangeList(1)"
          >
            <a-select-option
              v-for="status in statusList"
              :key="status.code"
              :value="status.code"
            >{{ status.name }}</a-select-option>
          </a-select>
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_exchange_050') }}: </span>
          <a-range-picker
            v-model:value="searchQuery.dateOpts"
            format="YYYY/MM/DD"
            value-format="YYYY-MM-DD"
            @change="loadExchangeList(1)"
          />
        </div>
        <div class="part flex-align">
          <span class="part-label">{{ $t('pages_exchange_051') }}: </span>
          <a-input
            v-model:value="searchQuery.batchNo"
            allow-clear
            @pressEnter="loadExchangeList(1)"
            @blur="loadExchangeList(1)"
          />
        </div>
      </div>
      <div class="table-part">
        <a-table
          :scroll="{ x: 1500 }"
          :loading="loading"
          :pagination="false"
          :custom-header-row="() => { return {class: 'my-table-tr'}}"
          :columns="columns"
          :data-source="exchangeList"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'edit'">
              <a-button
                v-if="record.status === '02' && themeData.electronicReceipt"
                shape="round"
                size="small"
                class="btn-primary-empty-0"
                @click="downExchangeReceipt(record.batchApplyNo)"
              >{{ $t('pages_exchange_058') }}</a-button>
            </template>
          </template>
        </a-table>
        <table-page
          :total="total"
          :page-opts="searchQuery"
          @update-page="updatePage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed, inject } from 'vue'
import HeaderNav from '@/components/header-nav'
import TablePage from '@/components/table-page'
import { _exchange } from '@/api'
import i18n from '@/locale'
import { getCountryLogo, parseMoney, getAttrStatusList, getTradeStatusName } from '@/util'
export default {
  name: 'ExchangeDetails',
  components: {
    'header-nav': HeaderNav,
    'table-page': TablePage
  },

  setup () {
    const state = reactive({
      themeData: inject('$themeData'),
      loading: true,
      total: 0,
      exportLoading: false,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        buyCurrency: '',
        sellCurrency: '',
        batchNo: '',
        status: '',
        dateOpts: []
      },
      buyCurList: [],
      sellCurList: [],
      exchangeList: [],
      columns: [
        { key: 'batchApplyNo', dataIndex: 'batchApplyNo', title: i18n.global.t('pages_exchange_051'), width: 200 },
        { key: 'sellCurrency', dataIndex: 'sellCurrency', title: i18n.global.t('pages_exchange_047'), width: 150 },
        { key: 'sellAmount', dataIndex: 'sellAmountText', title: i18n.global.t('pages_exchange_052'), width: 200 },
        { key: 'buyCurrency', dataIndex: 'buyCurrency', title: i18n.global.t('pages_exchange_048'), width: 150 },
        { key: 'buyAmount', dataIndex: 'buyAmountText', title: i18n.global.t('pages_exchange_053'), width: 200 },
        { key: 'rate', dataIndex: 'rate', title: i18n.global.t('pages_exchange_037'), width: 150 },
        { key: 'purpose', dataIndex: 'purposeText', title: i18n.global.t('pages_exchange_057'), width: 150 },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_exchange_054'), width: 150 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_exchange_060'), width: 200 },
        { key: 'edit', title: i18n.global.t('pages_exchange_056'), width: localStorage.getItem('locale-language') === 'en' ? 240 : 150, align: 'center', fixed: 'right' }
      ]
    })

    state.statusList = computed(() => {
      return getAttrStatusList('E')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadExchangeList()
    }

    const downExchangeReceipt = (batchNo) => {
      _exchange.downExchangeReceipt({ batchNo })
    }

    const exportExchangeFlow = async () => {
      state.exportLoading = true
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _exchange.exportExchange(query)
      state.exportLoading = false
    }

    const loadCurList = async () => {
      const res = await _exchange.getSupRateCur()
      if (res.data.succ) {
        const buysMap = {}
        const sellsMap = {}
        const buyCurList = []
        const sellCurList = []
        const { supList } = res.data.data

        if (!supList) {
          return
        } else {
          supList.map(item => {
            buysMap[item.buyCurrency] = item
            sellsMap[item.sellCurrency] = item
          })
        }

        for (const i in buysMap) {
          buyCurList.push({ name: buysMap[i].buyCurrency, code: buysMap[i].buyCurrency })
        }

        for (const i in sellsMap) {
          sellCurList.push({ name: sellsMap[i].sellCurrency, code: sellsMap[i].sellCurrency })
        }

        state.buyCurList = buyCurList
        state.sellCurList = sellCurList
      }
    }

    const loadExchangeList = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _exchange.getExchangeList(query)
      if (res.data.succ) {
        state.exchangeList = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            sellAmountText: parseMoney(item.sellAmount, item.sellCurrency),
            buyAmountText: parseMoney(item.buyAmount, item.buyCurrency),
            statusText: getTradeStatusName(item.status, 'E'),
            purposeText: getTradeStatusName(item.purpose, 'ePurpose'),
            updateTime: item.updateTime || '-'
          })
        })
      }
      state.total = res.data.data.total
      state.loading = false
    }

    loadCurList()
    loadExchangeList()

    return {
      updatePage,
      getCountryLogo,
      exportExchangeFlow,
      downExchangeReceipt,
      loadCurList,
      loadExchangeList,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#exchangeDetails {
  .edit-btn {
    display: flex;
    justify-content: flex-end;
    padding: 0 18px;
  }
}
</style>
